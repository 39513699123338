// Google Map
$.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCdVNYYCR1VbNQVlpOrmV88aNCIDsdGWKs', function (data, textStatus, jqxhr) {
    if (jqxhr.status === 200) {
        setTimeout(function () {
            // console.log('Google map initialized');
            initMap();
        }, 200)
    } else {
        console.error('Google maps not initialized');
    }
});
// Initialize and add the map
function initMap() {
    // The location of Uluru
    var gmapLat = $("#map-canvas").data('lat');
    var gmapLng = $("#map-canvas").data('lng');
    var myLatLng = new google.maps.LatLng(gmapLat, gmapLng);
    // The map, centered at Uluru
    var map = new google.maps.Map(
        document.getElementById('map-canvas'), {
            zoom: 14,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID]
            },
            navigationControlOptions: {
                style: google.maps.NavigationControlStyle.DEFAULT
            },
            center: myLatLng,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            // mapTypeId: "OSM",
            mapTypeControlOptions: {
                mapTypeIds: [
                    "OSM",
                    google.maps.MapTypeId.ROADMAP,
                    google.maps.MapTypeId.HYBRID
                ]
            }
        });
    //OSM
    map.mapTypes.set("OSM", new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
            return "http://" + String.fromCharCode(Math.floor(Math.random() * 3) + 97) + ".tile.openstreetmap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
        },
        tileSize: new google.maps.Size(256, 256),
        name: "OSM",
        maxZoom: 18
    }));
    // The marker, positioned at Uluru
    var marker = new google.maps.Marker({
        position: myLatLng,
        map: map
    });
    // var marker = [];
    // var infowindow = [];

    // $.each(mapCoordinates.coordinates, function (k, v) {
    //     marker[k] = new google.maps.Marker({
    //         position: new google.maps.LatLng(v.lat, v.lng),
    //         map: map,
    //         icon: '<: URL_ROOT>/site_resources/images/marker.png'
    //     });
    // });
	
	google.maps.event.addDomListener(window, "resize", function () {
    var center = map.getCenter();
    google.maps.event.trigger(map, "resize");
    map.setCenter(center);
});
}

